import { Button, Form } from "antd";
import { forwardRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DateInput } from "../../components/DateInput";

export const IntroStep3 = forwardRef<HTMLDivElement>((props, ref) => {
  const navigate = useNavigate();
  const handleClickNext = useCallback(() => {
    navigate("/hello/step4");
  }, [navigate]);

  return (
    <Wrapper {...props} ref={ref}>
      <Space $size={74} />
      <Title>
        What is your
        <br />
        birth date?
      </Title>
      <Space $size={26} />
      <Message>
        We share your birth information with the astrologer you pick.
      </Message>
      <Space $size={56} />
      <Form layout="vertical">
        <Form.Item label="Enter Date" name="birthday">
          <DateInput autoFocus />
        </Form.Item>
      </Form>
      <Space $flex={3.5} $size={30} />
      <Button
        className="big"
        htmlType="submit"
        type="primary"
        onClick={handleClickNext}
      >
        Continue
      </Button>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: Alice;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const Message = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
`;

const Space = styled.div<{ $size?: number; $flex?: number }>`
  ${({ $size, $flex }) => {
    if ($flex) {
      return `
        flex: ${$flex};
        min-height: ${$size || 0}px;
      `;
    }

    if ($size) {
      return `
        flex-basis: ${$size}px;
      `;
    }

    return "";
  }}
`;
