import { Navigate, createBrowserRouter } from "react-router-dom";

import { Layout } from "./pages/Layout";
import { Intro } from "./pages/Intro";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/hello/:id",
        element: <Intro />,
      },
      {
        path: "*",
        element: <Navigate to="/hello/step1" replace={true} />,
      },
      {
        path: "/",
        element: <Navigate to="/hello/step1" replace={true} />,
      },
    ],
  },
  {
    path: "*",
    element: <div>404</div>,
  },
]);
