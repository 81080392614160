import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import { GNB } from "../components/GNB";
import { Footer } from "../components/Footer";

type BackType = boolean | (() => void) | string;

export type LayoutContextValue = {
  back: BackType;
  isFold: boolean;
  setFold: (value: boolean) => void;
  setBack: (value: BackType) => void;
};

export const LayoutContext = createContext<LayoutContextValue>({
  back: false,
  isFold: false,
  setFold: () => {},
  setBack: () => {},
});

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [back, setBack] = useState<LayoutContextValue["back"]>(false);
  const [isFold, setFold] = useState(false);

  return (
    <LayoutContext.Provider value={{ back, isFold, setFold, setBack }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  return useContext(LayoutContext);
};

export const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <LayoutProvider>
      <Wrapper>
        <Container>
          <GNB />
          <Outlet />
          <Footer />
        </Container>
      </Wrapper>
    </LayoutProvider>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  width: min(500px, 100vw);
  margin: 0 auto;
  padding: 0 32px 48px 32px;
  display: flex;
  height: 100%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  * {
    -webkit-user-select: none; /* Safari와 크롬, 오페라 */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* 표준 값 */
  }
`;
