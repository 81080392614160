import { Button } from "antd";
import { forwardRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Title, Wrapper } from "./components";

export const IntroStep7 = forwardRef<HTMLDivElement>((props, ref) => {
  const navigate = useNavigate();
  const handleClickNext = useCallback(() => {
    navigate("/hello/step8");
  }, [navigate]);

  return (
    <Wrapper {...props} ref={ref}>
      <Space $size={74} />
      <Title>
        How direct do you
        <br />
        want the advice to be?
      </Title>
      <Space $size={80} />
      {[
        "I prefer radical honesty",
        "Prefer positivity and empathy",
        "Indifferent",
      ].map((key) => (
        <Button
          className="select"
          htmlType="submit"
          type="primary"
          onClick={handleClickNext}
          style={{ marginBottom: 12 }}
        >
          {key}
        </Button>
      ))}
    </Wrapper>
  );
});
