import { styled } from "styled-components";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: Alice;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

export const Message = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
`;

export const Space = styled.div<{ $size?: number; $flex?: number }>`
  ${({ $size, $flex }) => {
    if ($flex) {
      return `
        flex: ${$flex};
        min-height: ${$size || 0}px;
      `;
    }

    if ($size) {
      return `
        flex-basis: ${$size}px;
      `;
    }

    return "";
  }}
`;
