import { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { IntroStep1 } from "./Step1";
import { IntroStep2 } from "./Step2";
import { IntroStep3 } from "./Step3";
import { useLayout } from "../Layout";
import { IntroStep4 } from "./Step4";
import { IntroStep5 } from "./Step5";
import { IntroStep6 } from "./Step6";
import { IntroStep7 } from "./Step7";
import { IntroStep8 } from "./Step8";

export const Intro = () => {
  const { id } = useParams<{ id: string }>();
  const { setFold, setBack } = useLayout();
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const step4Ref = useRef<HTMLDivElement>(null);
  const step5Ref = useRef<HTMLDivElement>(null);
  const step6Ref = useRef<HTMLDivElement>(null);
  const step7Ref = useRef<HTMLDivElement>(null);
  const step8Ref = useRef<HTMLDivElement>(null);

  const nodeRef = useMemo(() => {
    let ref = step1Ref;

    switch (id) {
      case "step2":
        ref = step2Ref;
        break;
      case "step3":
        ref = step3Ref;
        break;
      case "step4":
        ref = step4Ref;
        break;
      case "step5":
        ref = step5Ref;
        break;
      case "step6":
        ref = step6Ref;
        break;
      case "step7":
        ref = step7Ref;
        break;
      case "step8":
        ref = step8Ref;
        break;
      default:
        ref = step1Ref;
    }
    return ref;
  }, [id]);

  useEffect(() => {
    setFold(["step3", "step4", "step5", "step6", "step7"].includes(id || ""));
    setBack(
      id === "step1"
        ? false
        : () => () => {
            window.history.back();
          }
    );
  }, [id, setBack, setFold]);

  return (
    <SwitchTransition>
      <CSSTransition
        key={id}
        nodeRef={nodeRef}
        addEndListener={(done) =>
          nodeRef.current?.addEventListener("transitionend", done, false)
        }
        classNames="fade"
      >
        <>
          {id === "step1" && <IntroStep1 ref={step1Ref} />}
          {id === "step2" && <IntroStep2 ref={step2Ref} />}
          {id === "step3" && <IntroStep3 ref={step3Ref} />}
          {id === "step4" && <IntroStep4 ref={step4Ref} />}
          {id === "step5" && <IntroStep5 ref={step5Ref} />}
          {id === "step6" && <IntroStep6 ref={step6Ref} />}
          {id === "step7" && <IntroStep7 ref={step7Ref} />}
          {id === "step8" && <IntroStep8 ref={step8Ref} />}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};
