import { Button } from "antd";
import { forwardRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Message, Space, Title, Wrapper } from "./components";

export const IntroStep1 = forwardRef<HTMLDivElement>((props, ref) => {
  const navigate = useNavigate();
  const handleClickNext = useCallback(() => {
    navigate("/hello/step2");
  }, [navigate]);

  return (
    <Wrapper {...props} ref={ref}>
      <Space $size={74} />
      <Title>
        Discover your path
        <br /> with Arth
      </Title>
      <Space $size={30} />
      <Message>
        Arth offers a seamless journey from discovery to consultation. Our
        carefully picked astrologers provide authentic readings to practical
        remedies, all in one place.
      </Message>
      <Space $flex={3} $size={30} />
      <Button
        className="big"
        htmlType="submit"
        type="primary"
        onClick={handleClickNext}
      >
        Next
      </Button>
    </Wrapper>
  );
});
