import { Button } from "antd";
import { forwardRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Message, Space, Title, Wrapper } from "./components";

export const IntroStep6 = forwardRef<HTMLDivElement>((props, ref) => {
  const navigate = useNavigate();
  const handleClickNext = useCallback(() => {
    navigate("/hello/step7");
  }, [navigate]);

  return (
    <Wrapper {...props} ref={ref}>
      <Space $size={74} />
      <Title>
        Any main areas
        <br />
        for consultation?
      </Title>
      <Space $size={26} />
      <Message>Please choose all that apply</Message>
      <Space $size={24} />
      {[
        "Love",
        "Family / Friends",
        "Career/Business",
        "Health",
        "Grief and Loss",
        "Education",
        "Self Help",
        "Other",
      ].map((key) => (
        <Button
          className="select"
          htmlType="submit"
          type="primary"
          onClick={handleClickNext}
          style={{ marginBottom: 12 }}
        >
          {key}
        </Button>
      ))}
      <Space $flex={1} />
      <Button
        className="big"
        htmlType="submit"
        type="primary"
        onClick={handleClickNext}
      >
        Continue
      </Button>
    </Wrapper>
  );
});
