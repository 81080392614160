import { Input } from "antd";
import moment, { Moment } from "moment";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";

export const DateInput: FC<{
  value?: Moment;
  onChange?: { (value: Moment | null): undefined };
  autoFocus?: boolean;
}> = ({ value, onChange, autoFocus }) => {
  const placeholder = "mm/dd/yy";
  const _refOnChange = useRef<typeof onChange>();
  const [placeholderValue1, setPlaceholderValue1] = useState("");
  const [placeholderValue2, setPlaceholderValue2] = useState("");
  const [fieldValue, setFieldValue] = useState(
    value ? moment(value, "MM/DD/YY").format("MM/DD/YY") : ""
  );
  const [isTouched, setIsTouched] = useState(false);

  _refOnChange.current = onChange;

  useEffect(() => {
    const suffix = placeholder?.substring(fieldValue?.length || 0, 1000);

    setPlaceholderValue1(fieldValue);
    setPlaceholderValue2(suffix);
  }, [fieldValue]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTouched(true);
    // let type = "unchanged";

    // if (fieldValue !== e.target.value) {
    //   if (fieldValue.length < e.target.value.length) {
    //     type = "added";
    //   } else if (fieldValue.length > e.target.value.length) {
    //     type = "removed";
    //   } else {
    //     type = "changed";
    //   }
    // }

    const normalized = e.target.value.replace(/[^0-9]/g, "");

    const _fieldValue = normalized
      .split("")
      .reduce<string[]>((acc, i, idx) => {
        const n = Math.floor(idx / 2);

        acc[n] = `${acc[n] || ""}${i}`;

        return acc;
      }, [])
      .join("/");

    setFieldValue(_fieldValue);
  }, []);

  useEffect(() => {
    if (/\d{2}\/\d{2}\/\d{2}/.test(fieldValue)) {
      const time = moment(fieldValue, "MM/DD/YY");

      if (time.isValid()) {
        _refOnChange.current?.(time);
      } else {
        _refOnChange.current?.(null);
      }
    } else {
      _refOnChange.current?.(null);
    }
  }, [fieldValue]);

  return (
    <Wrapper
      $isTouched={isTouched}
      $isValid={!!value && !!moment(value)?.isValid()}
    >
      <InputField
        value={fieldValue}
        onChange={handleChange}
        autoFocus={autoFocus}
        style={{ width: "100%" }}
        maxLength={8}
        inputMode="numeric"
        pattern="[0-9]*"
      />
      <Placeholder>
        <span style={{ color: "transparent" }}>{placeholderValue1}</span>
        {placeholderValue2}
      </Placeholder>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isTouched: boolean; $isValid: boolean }>`
  position: relative;
  height: 52px;

  ${({ $isTouched, $isValid }) => {
    if (!$isTouched) {
      return "";
    }

    if (!$isValid) {
      return `
        input {
          color: #f00;
        }
      `;
    }

    return "";
  }}
`;

const InputField = styled(Input)`
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  padding-left: 12px;
  line-height: 52px;
  color: #bcbcbc;
  pointer-events: none;
`;
