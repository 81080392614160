import { Button } from "antd";
import { forwardRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const IntroStep8 = forwardRef<HTMLDivElement>((props, ref) => {
  const navigate = useNavigate();
  const handleClickNext = useCallback(() => {
    // navigate("/hello/step8");
  }, [navigate]);

  return (
    <Wrapper {...props} ref={ref}>
      <Space $size={74} />
      <Title>
        You’ve completed
        <br />
        answering all the Qs!
      </Title>
      <Space $size={21} />
      <Message>You can change your answer anytime on My Profile</Message>
      <Space $flex={1} $size={30} />
      <Button
        className="big"
        htmlType="submit"
        type="primary"
        onClick={handleClickNext}
      >
        Awesome!
      </Button>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: Alice;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const Message = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
`;

const Space = styled.div<{ $size?: number; $flex?: number }>`
  ${({ $size, $flex }) => {
    if ($flex) {
      return `
        flex: ${$flex};
        min-height: ${$size || 0}px;
      `;
    }

    if ($size) {
      return `
        flex-basis: ${$size}px;
      `;
    }

    return "";
  }}
`;
