import { styled } from "styled-components";

import { useLayout } from "../../pages/Layout";
import iconBack from "./back.png";
import { useNavigate } from "react-router-dom";

export const GNB = () => {
  const navigate = useNavigate();
  const { back, isFold } = useLayout();

  const handleClickBack = () => {
    if (!back) return;

    if (typeof back === "function") {
      back();
    } else if (back === true) {
      navigate(-1);
    } else if (String(back).startsWith("http")) {
      window.location.href = back as string;
    } else {
      navigate(back);
    }
  };

  return (
    <Wrapper $fold={isFold}>
      {back && <BackButton className="back" onClick={handleClickBack} />}
      <Logo $fold={isFold} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $fold: boolean }>`
  position: relative;
  height: 46px;
  transition: margin 1s ease-in-out;

  ${({ $fold }) =>
    $fold
      ? `
          margin: 0 -32px 0 -32px;
        `
      : `
          margin: 0 -32px 100px -32px;
        `}
`;

const BackButton = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  width: 46px;
  height: 46px;
  margin: auto;
  background-image: url(${iconBack});
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 1s ease-in-out;
  transform: translateY(-50%);
  animation: fadeIn 1s ease-in-out;

  cursor: pointer;

  &:active {
    transition: none;
    opacity: 0.5;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Logo = styled.div<{ $fold?: boolean }>`
  position: absolute;
  ${({ $fold }) =>
    $fold
      ? `
        left: 50%;
        top: 50%;
        width: 30px;
        height: 34px;
        background-size: 36px;
      `
      : `
        left: 50%;
        top: 96px;
        width: 80px;
        height: 80px;
        background-size: 75px;
      `}
  margin: auto;
  background-image: url(/logo192.png);
  background-repeat: no-repeat;
  background-position: center;
  transition: all 1s ease-in-out;
  transform: translate(-50%, -50%);
`;
