import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { COLOR_PRIMARY } from "./constnats";
import { router } from "./router";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (/iP(ad|hone|od).+Version\/[\d\.]+.*Safari/i.test(navigator.userAgent)) {
  if ((window.navigator as any).standalone) {
    document.documentElement.classList.add("ios-safari-standalone");
  } else {
    document.documentElement.classList.add("ios-safari");
  }
}

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: COLOR_PRIMARY,
        },
        components: {
          Button: {
            borderRadius: 100,
            defaultShadow: "none",
            dangerShadow: "none",
          },
          Form: {
            labelFontSize: 14,
            fontFamily: 'Poppins, "sans-serif"',
          },
          Input: {
            borderRadius: 10,
            controlHeight: 52,
            lineHeight: 2,
            fontSize: 16,
            colorTextPlaceholder: "#BCBCBC",
            fontFamily: 'Poppins, "sans-serif"',
            colorBgContainer: "#F5F5F5",
          },
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
